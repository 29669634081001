import { FC } from "react";
import { GuidanceBox } from "@root/shared/components/GuidanceBox";
import { Icon } from "@root/shared/components/Icon";
import { colors } from "@root/shared/styles/sprinkles.css";
import * as styles from "./styles.css";

export const CautionaryNoteBox: FC = () => (
  <GuidanceBox themeColor="pink">
    <p className={styles.title}>投稿について</p>
    <ul className={styles.list}>
      <li className={styles.description}>
        <Icon fontSize="16px" name="close-view" color={colors.Biyo} />
        企業への口コミは、匿名で投稿されます。
      </li>
      <li className={styles.description}>
        <Icon fontSize="16px" name="attention" color={colors.Biyo} />
        個人情報やプライバシーへの配慮をした上で、口コミ投稿をお願いします。
      </li>
      <li className={styles.description}>
        <Icon fontSize="16px" name="edit" color={colors.Biyo} />
        1名様あたり最大10回まで投稿できます。（1企業につき1回）
      </li>
    </ul>
  </GuidanceBox>
);
