import { FC } from "react";
import { ABTestRenderer, Variant } from "@root/shared/components/ABTestRenderer";
import { VariantA } from "@userFrontend/features/entry/components/AppInstallButtonABTest/uiParts/VariantA";
import { VariantB } from "@userFrontend/features/entry/components/AppInstallButtonABTest/uiParts/VariantB";
import * as styles from "./styles.css";

type GAEvent = {
  gaLabel: string;
  gaAction: string;
};

type Props = {
  imgSrc: string;
  appUrl: string;
  ga: {
    message: GAEvent;
    useApp: GAEvent;
  };
};

export const AppInstallButtonABTest: FC<Props> = ({ imgSrc, appUrl, ga }) => (
  <div className={styles.container}>
    <ABTestRenderer testId="app_install_button_ABtest">
      <Variant id="A">
        <VariantA imgSrc={imgSrc} appUrl={appUrl} ga={ga.message} />
      </Variant>
      <Variant id="B">
        <VariantB imgSrc={imgSrc} appUrl={appUrl} ga={ga.useApp} />
      </Variant>
    </ABTestRenderer>
  </div>
);
