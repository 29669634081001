import { Link } from "@root/shared/components/Link";
import { Icon } from "@root/shared/components/Icon";
import * as styles from "@userFrontend/features/entry/components/AppInstallButtonABTest/styles.css";

type GAEvent = {
  gaLabel: string;
  gaAction: string;
};

type Props = {
  appUrl: string;
  ga: GAEvent;
  text: string;
};

export const AppInstallLink = ({ appUrl, text, ga: { gaAction, gaLabel } }: Props) => (
  <Link
    theme="primary"
    leftIcon={<Icon name="download" />}
    rightIcon={<Icon name="arrow-light-right" />}
    size="large"
    href={appUrl}
    className={styles.button}
    data-ga-action={gaAction}
    data-ga-label={gaLabel}
  >
    {text}
  </Link>
);
