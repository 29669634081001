import { FC, ReactElement, ReactNode, RefObject } from "react";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { sprinkles, colors } from "@root/shared/styles/sprinkles.css";
import { Icon } from "@root/shared/components/Icon";
import * as styles from "./styles.css";

type Props = {
  pcWidthType?: "small" | "large";
  spAppearanceType?: "center" | "bottom";
  headerTitle?: string;
  headerTitleStyle?: "gray" | "white" | "none";
  onClose?: () => void;
  children: ReactElement;
  footerContent?: ReactNode;
  footerContentStyle?: "none" | "button";
  canOutsideClick?: boolean;
  parentRef: RefObject<HTMLDivElement>;
  headerRef: RefObject<HTMLDivElement>;
  contentRef: RefObject<HTMLDivElement>;
  footerRef: RefObject<HTMLDivElement>;
  headerHeight: number;
  headerMarginBottom: number;
  footerHeight: number;
  hasOverFlow: boolean;
};

export const Presentation: FC<Props> = ({
  pcWidthType,
  spAppearanceType,
  headerTitle,
  headerTitleStyle,
  onClose,
  children,
  footerContent,
  footerContentStyle,
  parentRef,
  headerRef,
  headerHeight,
  headerMarginBottom,
  contentRef,
  footerRef,
  footerHeight,
  hasOverFlow,
  canOutsideClick,
}) => {
  return (
    <div
      className={styles.background}
      onClick={() => onClose && canOutsideClick && onClose()}
      aria-hidden="true"
    >
      <div
        ref={parentRef}
        className={`${spAppearanceType === "bottom" ? styles.wrapperBottomSheet : styles.wrapper}`}
        style={assignInlineVars({
          [styles.width]: pcWidthType === "small" ? "480px" : "833px",
        })}
        onClick={(e) => e.stopPropagation()}
        aria-hidden="true"
      >
        {/* header */}
        <div
          ref={headerRef}
          className={`${
            spAppearanceType === "bottom" ? styles.headerBottomSheet : styles.header
          } ${sprinkles({
            backgroundColor: headerTitleStyle === "gray" ? "BackgroundLight" : "White",
          })}`}
          style={assignInlineVars({
            [styles.headerHeight]:
              headerTitleStyle === "none" ? `${headerHeight}px` : "fit-content",
            [styles.headerTitlePosition]: headerTitle ? "space-between" : "right",
            [styles.marginBottom]: `${headerMarginBottom}px`,
          })}
        >
          {headerTitle && <p className={styles.title}>{headerTitle}</p>}
          <button
            type="button"
            className={styles.closeButton}
            onClick={onClose}
            aria-label="モーダルを閉じる"
          >
            <Icon name="cross-thin" color={colors.TextMedium} fontSize="18px" />
          </button>
        </div>
        {/* content */}
        <div
          ref={contentRef}
          className={spAppearanceType === "bottom" ? styles.contentBottomSheet : styles.content}
          style={assignInlineVars({
            [styles.headerHeight]: `${headerHeight}px`,
            [styles.headerMarginBottom]: `${headerMarginBottom}px`,
            [styles.bottomFooterHeight]: `${footerHeight}px`,
          })}
        >
          {children}
        </div>
        {/* footer */}
        <div
          ref={footerRef}
          className={`${styles.footerWrapper} ${sprinkles({
            boxShadow: hasOverFlow ? "LightGray" : "None",
          })}`}
          style={assignInlineVars({
            [styles.footerWrapperHeight]: footerContentStyle === "none" ? "40px" : "auto",
          })}
        >
          {!(spAppearanceType === "bottom" && footerContentStyle === "none" && !footerContent) && (
            <div
              className={spAppearanceType === "bottom" ? styles.footerBottom : styles.footer}
              style={assignInlineVars({
                [styles.footerHeight]: footerContentStyle === "none" ? "40px" : "auto",
              })}
            >
              {footerContent}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
