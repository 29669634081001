import { ComponentPropsWithRef, FC, forwardRef } from "react";
import * as styles from "./styles.css";

type Props = Omit<ComponentPropsWithRef<"select">, "type"> & {
  selected: boolean;
  hasError?: boolean;
  placeholder?: string;
  options: OptionProps[];
};

export type OptionProps = {
  label: string;
  value: number | string;
  disabled?: boolean;
};

export const SelectBox: FC<Props> = forwardRef(
  ({ selected, hasError, className, placeholder, options, ...props }, ref) => (
    <select
      className={`${styles.variant[selected ? "selected" : "unselected"]} ${
        hasError ? styles.hasError : ""
      } ${className || ""}`}
      {...props}
      ref={ref}
    >
      {placeholder && (
        <option value="" className={styles.placeholderOption}>
          {placeholder}
        </option>
      )}
      {/* eslint-disable-next-line no-shadow */}
      {options.map(({ label, value, disabled = false }) => (
        <option key={label} value={value} disabled={disabled} className={styles.option}>
          {label}
        </option>
      ))}
    </select>
  ),
);
SelectBox.displayName = "SelectBox";
