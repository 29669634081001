import { FC } from "react";
import { GuidanceBox } from "@root/shared/components/GuidanceBox";
import * as styles from "./styles.css";

export const RewardGuidanceBox: FC = () => (
  <GuidanceBox themeColor="gray">
    <p className={styles.title}>謝礼お渡しのルール</p>
    <ul className={styles.list}>
      <li className={styles.description}>
        原則として、謝礼は回答月の翌月末までに、ご登録のメールアドレスにお送りいたします。（26日以降は翌々月末）
      </li>
      <li className={styles.description}>
        リジョブや関連サイトへの公開に適さない回答は、付与の対象外です。付与の対象外となる場合はご連絡いたしませんので、ご了承ください。
      </li>
    </ul>
  </GuidanceBox>
);
