import * as styles from "@userFrontend/features/entry/components/AppInstallButtonABTest/styles.css";
import { AppInstallLink } from "@userFrontend/features/entry/components/AppInstallButtonABTest/uiParts/AppInstallLink/index";

type Props = {
  imgSrc: string;
  appUrl: string;
  ga: {
    gaLabel: string;
    gaAction: string;
  };
};

export const VariantB = ({ imgSrc, appUrl, ga }: Props) => (
  <>
    <div className={styles.inner}>
      <img src={imgSrc} alt="アプリのアイコン" />
      <p className={styles.text}>
        アプリを使うと
        <br />
        <b>採用率1.75倍！</b>
      </p>
    </div>
    <AppInstallLink appUrl={appUrl} ga={ga} text="アプリを使ってみる" />
  </>
);
