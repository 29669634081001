import * as styles from "@userFrontend/features/entry/components/AppInstallButtonABTest/styles.css";
import { AppInstallLink } from "@userFrontend/features/entry/components/AppInstallButtonABTest/uiParts/AppInstallLink/index";

type Props = {
  imgSrc: string;
  appUrl: string;
  ga: {
    gaLabel: string;
    gaAction: string;
  };
};

export const VariantA = ({ imgSrc, appUrl, ga }: Props) => (
  <>
    <div className={styles.inner}>
      <img src={imgSrc} alt="アプリのアイコン" />
      <p className={styles.text}>
        企業からのメッセージ確認は
        <br />
        <b>アプリが便利！</b>
      </p>
    </div>
    <AppInstallLink appUrl={appUrl} ga={ga} text="アプリでメッセージを確認する" />
  </>
);
